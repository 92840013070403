import autoComplete from '@tarekraafat/autocomplete.js';

// Put time hover text in current locale.
document.querySelectorAll('time').forEach(
    e => e.title = new Date(e.dateTime).toLocaleString());

// Add autocomplete to the search box.
new autoComplete({
    resultItem:  { highlight: true },
    resultsList: { maxResults: 10 },
    selector:    '#search input',
    submit:      true,
    wrapper:     false,
    data: {
        src: async q => await (
            await fetch('/autocomplete?q=' + encodeURIComponent(q))).json(),
    },
    events: {
        input: {
            selection: e => {
                e.target.value = e.detail.selection.value;
                e.target.form.submit();
            },
        },
    },
});
